
import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import Particles from "react-tsparticles";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import CounterTwo from "../elements/counters/CounterTwo";
import Testimonial from "../elements/Testimonial";
import Team from "../elements/Team";
import BlogContent from "../elements/blog/BlogContent";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";
import logo from "../assets/images/prismatica/prismaticalogo.png";
import logoLight from "../assets/images/logo/logoDarkPrismatica.png";
import logoAllDark from "../assets/images/logo/logoLightPrismatica.png";
import portfolio16 from "../assets/images/portfolio/interior/portfolio-16.png";
import portfolio17 from "../assets/images/portfolio/interior/portfolio-17.png";
import portfolio18 from "../assets/images/portfolio/interior/portfolio-18.png";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import { HashLink } from 'react-router-hash-link';

const Portfolio_image = <img src={portfolio16} alt="React Creative Agency" />;
const Portfolio_image2 = <img src={portfolio17} alt="React Creative Agency" />;
const Portfolio_image3 = <img src={portfolio18} alt="React Creative Agency" />;

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Prismatica",
    description:
      "Your Vision, Our Code.",
    buttonText: "Contact Us",
    buttonLink: "#contact",
  },
];

const PortfolioList = [
  {
    image: Portfolio_image,
    category: "Real Estate",
    title: "Harman Kular Real Estate",
    description:
      "A responsive and mobile friendly website carefully crafted for optimal user experience.",
      link: "https://soldbyharmank.com/"
  },
  {
    image: Portfolio_image2,
    category: "Construction",
    title: "Scandia Paving",
    description:
      "Construction company website with a contact form to create seamless communication between clients.",
      link: "https://scandiapaving.com/"
  },
  {
    image: Portfolio_image3,
    category: "Law",
    title: "Invictus Law",
    description:
      "A law website created to allow important law office clients to connect with staff for any matter.",
      link: "#portfolio"
  },
];

class PrismaticaHome extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}

  render() {

    const PostList = BlogContent.slice(0, 5);

    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <Fragment>
        <Helmet pageTitle="Prismatica" />

        {/* Start Header Area  */}
        <header className="header-area formobile-menu header--fixed default-color">
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <img className="logo-1" src={logoLight} alt="Logo Images" />
                  <img className="logo-2" src={logoAllDark} alt="Logo Images" />
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <Scrollspy
                  className="mainmenu"
                  items={[
                    "home",
                    "service",
                    "about",
                    "portfolio",
                    "team",
                    "testimonial",
                    "blog",
                    "contact",
                  ]}
                  currentClassName="is-current"
                  offset={-200}
                >
                  <li>
                    <HashLink to="#home" onClick={this.CLoseMenuTrigger}>Home</HashLink>
                  </li>
                  <li>
                    <HashLink to="#service" onClick={this.CLoseMenuTrigger}>Service</HashLink>
                  </li>
                  <li>
                    <HashLink to="#about" onClick={this.CLoseMenuTrigger}>About</HashLink>
                  </li>
                  <li>
                    <HashLink to="#portfolio" onClick={this.CLoseMenuTrigger}>Portfolio</HashLink>
                  </li>
                  <li>
                    <HashLink to="#team" onClick={this.CLoseMenuTrigger}>Team</HashLink>
                  </li>
                  <li>
                    <HashLink to="#testimonial" onClick={this.CLoseMenuTrigger}>Testimonial</HashLink>
                  </li>
                  <li>
                    <HashLink to="#blog" onClick={this.CLoseMenuTrigger}>Blog</HashLink>
                  </li>
                  <li>
                    <HashLink to="#contact" onClick={this.CLoseMenuTrigger}>Contact Us</HashLink>
                  </li>
                </Scrollspy>
              </nav>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div
          className="slider-activation slider-creative-agency with-particles"
          id="home"
        >
          <div className="frame-layout__particles" style={{zIndex: 1}}> {/* for now ive set the zindex to 1  */}
            <Particles
              className="particle"
              options={{
                style: {
                  position: "absolute"
                },
                fpsLimit: 100,
                interactivity: {
                  detectsOn: "canvas",
                  events: {
                    onClick: {
                      enable: true,
                      mode: "push",
                    },
                    onHover: {
                      enable: true,
                      mode: "repulse",
                    },
                    resize: true,
                  },
                  modes: {
                    bubble: {
                      distance: 100,
                      duration: 2,
                      opacity: 0.8,
                      size: 10,
                      color: "#888",
                    },
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 100,
                      duration: 0.4,
                      color: "#888",
                    },
                  },
                },
                particles: {
                  color: {
                    value: "#888",
                  },
                  links: {
                    color: "#888",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                  },
                  collisions: {
                    enable: true,
                  },
                  move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: false,
                    speed: 6,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      value_area: 2000,
                    },
                    value: 80,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: "circle",
                  },
                  size: {
                    random: true,
                    value: 5,
                  },
                },
                detectRetina: true,
              }}
            />
          </div>
          <div className="im_modern_slider bg_image bg_image--1" data-black-overlay="8" position="relative" zIndex="10">
            {SlideList.map((value, index) => (
              <div
                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                key={index}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1 className="title">{value.title}</h1>
                        ) : (
                          ""
                        )}
                        <img style={{ width: '11rem', height: 'auto' }} src={logo} alt="Prismatica"></img>
                        {value.description ? (
                          <p className="description_style-2">
                            {value.description}
                          </p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <HashLink
                              className="btn-default btn-large"
                              to={`${value.buttonLink}`}
                            >
                              {value.buttonText}
                            </HashLink>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area  */}
        <div
          className="service-area creative-service-wrapper ptb--120 bg_color--1"
          id="service"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="section-title text-left mb--30">
                  <span className="subtitle">What we can do for you</span>
                  <h2 className="title">Services We Provide</h2>
                  <p className="description">
                  At <b style={{color: '#F16D32'}}>Prismatica</b>, we provide a comprehensive range of services designed to elevate your digital presence and drive business success.
                  <br /> Explore our offerings below: 
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--5" id="about">
          <AboutTwo/>
        </div>
        {/* End About Area */}

        {/* Start Portfolio Area */}
        <div
          className="portfolio-area pt--120 pb--140 bg_color--1"
          id="portfolio"
        >
          <div className="rn-slick-dot">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-left mb--30 mb_sm--0">
                    <span className="subtitle">Our Projects</span>
                    <h2 className="title">Some of our Recent Work</h2>
                    <p className="description">
                    Showcasing Our Craftsmanship
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {PortfolioList.map((value, index) => (
                  <div className="col-lg-6 col-sm-6 col-12 mt--30" key={index}>
                    <div className="im_portfolio">
                      <div className="thumbnail_inner">
                        <div className="thumbnail">
                          <a href={value.link}>{value.image}</a>
                        </div>
                      </div>
                      <div className="content">
                        <div className="inner">
                          <div className="portfolio_heading">
                            <div className="category_list">
                              <a href={value.link}>
                                {value.category}
                              </a>
                            </div>
                            <h4 className="title">
                              <a href={value.link}>{value.title}</a>
                            </h4>
                          </div>
                          <div className="portfolio_hover">
                            <p>{value.description}</p>
                          </div>
                        </div>
                      </div>
                      <a
                        className="transparent_link"
                        href={value.link}
                      ></a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area pt--140 p pb--110 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle">Expert Growth</span>
                  <h2 className="title">Our Company Growth</h2>
                </div>
              </div>
            </div>
            <CounterTwo />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Team Area  */}
        <div className="rn-team-area ptb--120 bg_color--1" id="team">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-left mb--25 mb_sm--0">
                  <span className="subtitle">Our Experts</span>
                  <h2 className="title">Skilled Team</h2>
                  <p className="description">
                  Our team is the heartbeat of our success. We are a diverse group of creative thinkers, skilled developers, <br/>and strategic experts who come together to deliver exceptional digital solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start Testimonial Area */}
        <div
          className="rn-testimonial-area bg_color--5 ptb--120"
          id="testimonial"
        >
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Blog Area */}
        <div className="rn-blog-area pt--120 pb--140 bg_color--1" id="blog">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title text-left">
                  <span className="subtitle">Market Trends</span>
                  <h2 className="title">Latest News</h2>
                  <p className="description">
                    Whats the latest in tech?
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--10 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0">
              <div className="col-lg-12">
                <Slider {...slickDot}>
                  {PostList.map((value, i) => (
                    <div className="im_box" key={i}>
                      <div className="thumbnail">
                        <a href={value.link} target="_blank">
                          <img
                            className="w-100"
                            src={value.images}
                            alt="Blog Images"
                          />
                        </a>
                      </div>
                      <div className="content">
                        <div className="inner">
                          <div className="content_heading">
                            <div className="category_list">
                              <a href={value.link} target="_blank">
                                {value.category}
                              </a>
                            </div>
                            <h4 className="title">
                              <a href={value.link} target="_blank" >{value.title}</a>
                            </h4>
                          </div>
                          <div className="content_footer">
                            <a
                              href={value.link}
                              target="_blank"
                              className="rn-btn btn-opacity"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                        <a
                          className="transparent_link"
                          target="_blank"
                          href={value.link}
                        ></a>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Area */}

        {/* Start Contact Us */}
        <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
          <Contact />
        </div>
        {/* End Contact Us */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default PrismaticaHome;
