import React, { Component } from "react";
import { FaFacebookF , FaLinkedinIn } from "react-icons/fa";

import team1 from "../assets/images/team/team-15.jpeg";
import team2 from "../assets/images/team/team-16.jpeg";


let TeamContent = [
    {
        images: team1,
        title: 'Amar Singara',
        designation: 'Lead Web Developer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/amarsingara111/'
            },
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/amarpreet-singara-1613741b1/'
            }
        ]
    },
    {
        images: team2,
        title: 'Zorawar Randhawa',
        designation: 'Lead Web Developer',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/Zorawar22'
            },
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/zorawar-randhawa/'
            },
        ]
    }
];


class Team extends Component{
    render(){
        const {column} = this.props;
        return (
          <React.Fragment>
            {TeamContent.map((value, i) => (
              <div className={`${column}`} key={i}>
                <div className="team">
                  <div className="thumbnail">
                    <img src={value.images} alt="Blog Images" />
                  </div>
                  <div className="content">
                    <h4 className="title">{value.title}</h4>
                    <p className="designation">{value.designation}</p>
                  </div>
                  <ul className="social-icon">
                    {value.socialNetwork.map((social, index) => (
                      <li key={index}>
                        <a href={`${social.url}`} target="_blank">{social.icon}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </React.Fragment>
        );
    }
}
export default Team;