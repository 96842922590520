import React, { Component } from "react";

import aboutImg3 from "../../../assets/images/about/aboutPrismatica.jpg";

class AboutTwo extends Component {
  render() {
    let title = "About",
      description =
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--20 align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="thumbnail">
                  <img className="w-100" src={aboutImg3} alt="About Images" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <span className="subtitle">Read About Us</span>
                    <h2 className="title"><span style={{color: '#F16D32'}}>Prismatica</span> Company Values</h2>
                    <p className="description">
                      <u>Our mission is simple:</u> to create exceptional digital experiences <br /> that help our clients achieve their business goals.
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h5 className="title">Creative Excellence:</h5>
                        <p>We blend creativity with strategy to deliver designs that are not only visually stunning but also strategically effective. Our designs are crafted to captivate and convert.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h5 className="title">Technical Expertise:</h5>
                        <p>Our team of skilled developers uses the latest technologies and best practices to build websites that are fast, secure, and scalable. We stay ahead of industry trends to ensure your site is cutting-edge.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h5 className="title">Client-Centric Approach:</h5>
                        <p>We put our clients at the heart of everything we do. From initial consultation to project completion, we prioritize clear communication and collaboration to ensure your vision is realized.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h5 className="title">Built-In Protection</h5>
                        <p>Custom coding allows us to implement robust security measures tailored to your specific requirements. By avoiding the vulnerabilities of pre-built templates, we create a more secure environment for your data and users.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutTwo;
