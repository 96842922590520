import React , {useState, useEffect} from 'react';
import { useForm } from '@formspree/react';


function FormStyle({props}) {
    const [state, handleSubmit, reset] = useForm("mkgwyzvj");

    if (state.succeeded) {
        return (
            <div>
                <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p><button className='btn-default' onClick={reset}>Reset</button>
            </div>
        );
    }

    return (
        <div className="form-wrapper">
            <form onSubmit={handleSubmit}>
                <div className="rn-form-group">
                    <input 
                    type="text"
                    name="fullname"
                    placeholder="Your Name"
                    required
                    />
                </div>

                <div className="rn-form-group">
                    <input 
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    required
                    />
                </div>

                <div className="rn-form-group">
                    <input 
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    required
                    />
                </div>

                <div className="rn-form-group">
                    <input 
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required
                    />
                </div>
                
                <div className="rn-form-group">
                    <textarea 
                    name="message"
                    placeholder="Your Message"
                    required
                    >
                    </textarea>
                </div>

                <div className="rn-form-group">
                    <button className="btn-default" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit Now</button>
                </div> 
            </form>
        </div>
    )
}
export default FormStyle;