import blogImg1 from "../../assets/images/blog/blog-10.jpg";
import blogImg2 from "../../assets/images/blog/blog-11.jpg";
import blogImg3 from "../../assets/images/blog/blog-12.jpg";


const BlogContent = [
  {
    images: blogImg1,
    title: "AMD’s Brand Value Surpasses Intel’s for the First Time.",
    category: "Latest Tech",
    link: "https://www.technewsworld.com/story/amds-brand-value-surpasses-intels-for-the-first-time-179337.html"
  },
  {
    images: blogImg2,
    title: "Meta and Nvidia: The Future of Social Media and Personal Technology.",
    category: "Latest Tech",
    link: "https://www.technewsworld.com/story/meta-and-nvidia-the-future-of-social-media-and-personal-technology-179307.html"
  },
  {
    images: blogImg3,
    title: "A Glimpse Into the Future of AI Electric Cars.",
    category: "Latest Tech",
    link: "https://www.technewsworld.com/story/a-glimpse-into-the-future-of-ai-electric-cars-179300.html"
  }
];

export default BlogContent;
