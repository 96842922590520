import React ,{ Component }from "react";
import { FiCast , FiLayers , FiSearch , FiUserPlus, FiSettings } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Custom Web Design',
        description: "We create visually stunning, responsive websites tailored to your brand's identity. Our design process focuses on delivering a seamless user experience that captivates visitors and encourages engagement. From sleek, modern aesthetics to classic, sophisticated designs, we ensure your website reflects your unique style and objectives."
    },
    {
        icon: <FiLayers />,
        title: 'Website Development',
        description: 'Our development team builds robust, scalable websites using the latest technologies and best practices. We specialize in both front-end and back-end development, ensuring your site is fast, secure, and functional. Whether you need a simple informational site or a complex, interactive platform, we have the expertise to bring your vision to life.'
    },
    {
        icon: <FiSearch />,
        title: 'Search Engine Optimization (SEO)',
        description: "Increase your website’s visibility and attract more organic traffic with our SEO services. We perform comprehensive keyword research, optimize on-page elements, and build high-quality backlinks to improve your search engine rankings. Our data-driven approach ensures that your site is easily discoverable by potential customers."
    },
    { 
        icon: <FiUserPlus />,
        title: 'Brand Identity Design',
        description: 'Create a powerful brand presence with our identity design services. We develop cohesive branding elements, including logos, color schemes, typography, and visual assets, to establish a strong and memorable brand image. Our designs are crafted to resonate with your target audience and reinforce your market position.'
    },
    {
        icon: <FiSettings />,
        title: 'Website Maintenance and Support',
        description: 'Keep your website running smoothly with our ongoing maintenance and support services. We offer regular updates, security checks, and performance optimization to ensure your site remains functional and up-to-date. Our responsive support team is available to address any issues promptly and keep your site performing at its best.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <div className="service service__style--2 text-left bg-gray">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h3 className="title">{val.title}</h3>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
