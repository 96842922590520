import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import testimonial1 from "../assets//images/client/goldenera.jpg";
import testimonial2 from "../assets//images/client/test2.png";
import testimonial3 from "../assets//images/client/test3.png";
import testimonial4 from "../assets//images/client/test4.png";

class Testimonial extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>
                        "The team at Prismatica delivered a website that perfectly captures our brand. The design is stunning and functional, and we've seen a boost in traffic. Highly recommended!"
                        </p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>Prince Kular</span> - SCANDIA PAVING
                          INC.
                        </h6>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>
                        "We’re thrilled with the results from Prismatica. Their SEO and design expertise boosted our online presence. They were professional and exceeded our expectations!"
                        </p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>Jovan Sagoo</span> - PACIFIC ARCH INC.
                        </h6>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>
                        "The creative team at Prismatics designed a beautiful, user-friendly site. The project was on time and within budget. Highly efficient and detail-oriented!"
                        </p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>Harman Kular </span> - SOLD BY HARMAN K
                        </h6>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>
                        "Absolutely thrilled with the logo Amar & Zorawar created for us! It perfectly captures our brand’s identity and looks fantastic"
                        </p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>Sunny Sekhon</span> - GOLDEN ERA LIGHTING
                        </h6>
                      </div>
                    </div>
                  </TabPanel>
                </div>
                <div className="col-lg-6 mt_md--40 mt_sm--40">
                  <TabList className="testimonial-thumb-wrapper">
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial3} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial2} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial4} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img src={testimonial1} alt="Testimonial Images" />
                        </div>
                      </div>
                    </Tab>
                  </TabList>
                </div>
              </div>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
